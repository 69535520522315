import React, { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { Avatar, Popover } from "antd";
import PlusIcon from "features/app/assets/icons/PlusIcon";
import PopoverContent from "features/app/components/append-employees/popover-content/popover-content";
import { UserSelectModel } from "features/app/utils/models/user/userSelectModel";
import { CompanyPersonModel } from "features/projects/utils/models/workModel";

import { AppendedEmployeesFormType } from "modules/append-employees";
import { colors, SuccessRes } from "modules/common";

import styles from "./append-employees.module.scss";

type Props = {
  users: UserSelectModel[];
  company_persons: CompanyPersonModel[];
  updateEmployees: ({
    user_ids,
    company_person_ids
  }: {
    user_ids: number[];
    company_person_ids: number[];
  }) => Promise<SuccessRes>;
  children?: ReactNode;
};

const AppendEmployees: React.FC<Props> = ({ company_persons, users, updateEmployees, children }) => {
  const [open, setOpen] = React.useState(false);
  const form = useForm<AppendedEmployeesFormType>({
    defaultValues: {
      user_ids: users?.map(el => el?.id),
      company_person_ids: company_persons?.map(el => el?.id)
    }
  });

  const usersData = ():
    | { image: string | null | undefined; name: string | undefined; id: number | undefined }[]
    | undefined => {
    if (company_persons && company_persons?.length > 0 && users && users?.length === 0) {
      return company_persons?.map(item => ({
        id: item?.id,
        name: item?.name,
        image: ""
      }));
    }
    if (users && users?.length > 0 && company_persons && company_persons?.length === 0) {
      return users?.map(item => ({
        id: item?.id,
        name: item?.full_name,
        image: item?.image
      }));
    }
    if (users && users?.length > 0 && company_persons && company_persons?.length > 0) {
      return [
        ...users.map(item => ({
          id: item?.id,
          name: item?.full_name,
          image: item?.image
        })),
        ...company_persons.map(item => ({
          id: item?.id,
          name: item?.name,
          image: ""
        }))
      ];
    }
    return undefined;
  };

  const onCancel = (open: boolean) => {
    setOpen(open);
    if (open) {
      form.reset({
        company_person_ids: company_persons?.map(el => el.id),
        user_ids: users?.map(el => el.id)
      });
    }
  };

  return (
    <Popover
      onOpenChange={onCancel}
      arrow={false}
      open={open}
      trigger={["click"]}
      rootClassName={styles.popover}
      placement="topRight"
      content={
        <PopoverContent
          users={users}
          company_persons={company_persons}
          form={form}
          updateEmployees={updateEmployees}
          setPopoverOpen={setOpen}
        />
      }
    >
      <div className="flex cursor-pointer items-center gap-3" onClick={() => setOpen(true)}>
        {!children ? (
          <div className="flex h-8 w-8 items-center justify-center rounded-[50%] border border-dashed border-gray-300">
            <PlusIcon color={colors.GRAY_400} />
          </div>
        ) : (
          children
        )}
        {!children && usersData() && (
          <Avatar.Group
            max={{
              count: 5,
              style: { color: "#475467", backgroundColor: "#F2F4F7" },
              popover: { content: null }
            }}
            size={38}
          >
            {usersData()
              ? usersData()?.map(item => (
                  <Avatar
                    style={{
                      color: colors.GRAY_600,
                      fontSize: "12px",
                      fontWeight: 500,
                      backgroundColor: colors.GRAY_100
                    }}
                    src={item?.image}
                    key={item.id}
                  >
                    {item?.name?.[0]}
                  </Avatar>
                ))
              : ""}
          </Avatar.Group>
        )}
      </div>
    </Popover>
  );
};

export default AppendEmployees;
