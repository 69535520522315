import React, { ReactNode, useState } from "react";
import { Modal, Form } from "antd";
import { useTranslation } from "react-i18next";
import { useCreateProduct } from "features/projects/service/mutations";

import Content from "./content";
import { useAppSelector } from "hooks/redux";

const { useForm } = Form;

type Props = {
  type: "product" | "person" | "mechanism";
  title: string;
  children: ReactNode;
  taskId: number | undefined;
};

const AddModal: React.FC<Props> = ({ type, title, children, taskId }) => {
  const [open, setOpen] = useState(false);
  const [form] = useForm();
  const { t } = useTranslation();
  const record = useAppSelector(state => state.resourceSpendingReducer.drawer?.record);

  const handleClose = () => {
    setOpen(false);
    form.resetFields();
  };
  const mutate = useCreateProduct(true, handleClose);

  const onFinish = (values: { product_id: number; unit_id: number }) => {
    mutate.mutate({
      product_ids: [values?.product_id],
      unit_id: values?.unit_id,
      task_id: record?.id ?? taskId
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const onSave = () => {
    form.submit();
  };

  return (
    <>
      <div className="w-full" onClick={handleOpen}>
        {children}
      </div>
      <Modal
        centered
        open={open}
        width={560}
        title={title}
        okText={t("works.Saqlash")}
        cancelText={t("works.Yopish")}
        okButtonProps={{
          htmlType: "submit",
          onClick: onSave,
          type: "primary",
          loading: mutate.isLoading
        }}
        onCancel={handleClose}
      >
        <Content form={form} onFinish={onFinish} type={type} />
      </Modal>
    </>
  );
};

export default AddModal;
