import { ResourceSpendingDrawerEnums } from "modules/works";
import React, { ReactNode, useEffect } from "react";
import { FormInstance, Form } from "antd";
import Products from "./products";
import CounterPart from "./counterpart";
import Mechanism from "./mechanism";
import { useAppSelector } from "hooks/redux";
import { useGetResourceSpendingData } from "modules/works/queries/use-get-resource-speding-data";

const { Item, useWatch } = Form;

type Props = {
  currentTab: ResourceSpendingDrawerEnums;
  form: FormInstance;
};

const TabContents: React.FC<Props> = ({ currentTab, form }) => {
  const record = useAppSelector(state => state.resourceSpendingReducer.drawer.record);
  const taskId = useWatch("task_id", form);

  const { resourceSpendingView, isLoading } = useGetResourceSpendingData({
    task_id: record?.id ?? taskId,
    enabled: true
  });

  const products = resourceSpendingView?.filter(el => el?.product?.resource?.type === "product");
  const counterparts = resourceSpendingView?.filter(el => el?.product?.resource?.type === "person");
  const mechanisms = resourceSpendingView?.filter(el => el?.product?.resource?.type === "mechanism");

  const content: {
    [key in ResourceSpendingDrawerEnums]: ReactNode;
  } = {
    [ResourceSpendingDrawerEnums.PRODUCTS]: (
      <Item
        shouldUpdate
        className="!h-full [&_.ant-form-item-control-input-content]:h-full [&_.ant-form-item-control-input]:h-full [&_.ant-form-item-control-input]:items-start [&_.ant-form-item-row]:h-full [&_.ant-form-item]:h-full"
        initialValue={[]}
      >
        <Products data={products} form={form} isLoading={isLoading} />
      </Item>
    ),
    [ResourceSpendingDrawerEnums.COUNTERPART]: (
      <Item
        shouldUpdate
        className="!h-full [&_.ant-form-item-control-input-content]:h-full [&_.ant-form-item-control-input]:h-full [&_.ant-form-item-control-input]:items-start [&_.ant-form-item-row]:h-full [&_.ant-form-item]:h-full"
        initialValue={[]}
      >
        <CounterPart data={counterparts} form={form} isLoading={isLoading} />
      </Item>
    ),
    [ResourceSpendingDrawerEnums.MECHANISM]: (
      <Item
        shouldUpdate
        className="!h-full [&_.ant-form-item-control-input-content]:h-full [&_.ant-form-item-control-input]:h-full [&_.ant-form-item-control-input]:items-start [&_.ant-form-item-row]:h-full [&_.ant-form-item]:h-full"
        initialValue={[]}
      >
        <Mechanism data={mechanisms} form={form} isLoading={isLoading} />
      </Item>
    )
  };

  return <div>{content[currentTab]}</div>;
};

export default TabContents;
