import React from "react";
import { FormInstance, Tabs, Form } from "antd";
import { ResourceSpendingDrawerEnums, ResourceSpendingDrawerTabItems } from "modules/works";
import { useTranslation } from "react-i18next";
import { EmptyContent } from "components";
import { cx } from "modules/common";

import TabContents from "./tab-contents";
import TabExtraContent from "./tab-extra-content";

import styles from "./index.module.scss";

const { useWatch } = Form;

type Props = {
  form: FormInstance<any>;
  currentTab: ResourceSpendingDrawerEnums;
  setCurrentTab: React.Dispatch<React.SetStateAction<ResourceSpendingDrawerEnums>>;
};

const Bottom: React.FC<Props> = ({ form, currentTab, setCurrentTab }) => {
  const { t } = useTranslation();
  const handleTabChange = (key: ResourceSpendingDrawerEnums) => {
    setCurrentTab(key);
  };

  const workId = useWatch("task_id", form);

  return (
    <div className={cx("h-full", !workId && "flex items-center justify-center")}>
      {workId ? (
        <Tabs
          activeKey={currentTab}
          rootClassName={styles.tabs}
          items={ResourceSpendingDrawerTabItems(<TabContents currentTab={currentTab} form={form} />, t)}
          tabBarExtraContent={
            <TabExtraContent
              type={
                currentTab === ResourceSpendingDrawerEnums.PRODUCTS
                  ? "product"
                  : currentTab === ResourceSpendingDrawerEnums.COUNTERPART
                    ? "person"
                    : "mechanism"
              }
              taskId={workId}
            />
          }
          onChange={handleTabChange as never}
        />
      ) : (
        <EmptyContent />
      )}
    </div>
  );
};

export default Bottom;
