import React, { useState } from "react";
import { Select, SelectProps, Spin, Form } from "antd";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { TooltipShortName } from "modules/common";
import { ProductAll, TargetType, useProductAll } from "modules/dashboard";
import { TimeoutModel } from "features/app/utils/models/TimeoutModel";
import { LoadingIndicator, SelectSuffixIcon } from "components";
import { selectFilterOption } from "features/app/utils/helpers/selectFilterOption";
import SelectNotContent from "features/app/components/select-not-content/SelectNotContent";
import { Rule } from "antd/es/form";
import { FormInstance } from "antd/lib";
import { DefaultOptionType } from "antd/es/select";

const { Option } = Select;
const { Item } = Form;

type Props = {
  type?: string;
  isHave?: "yes" | "no";
  allowClear?: boolean;
  rules?: Rule[];
  label?: string;
  form?: FormInstance<any>;
};

const ProductSelect: React.FC<Props> = ({ isHave, type, allowClear, rules, label, form }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>();
  const [time, setTime] = useState<TimeoutModel>();
  const { pages, fetchNextPage, isLoading } = useProductAll({ search, isHave, type });

  const products = () => {
    let result: ProductAll[] = [];

    pages?.forEach(page => {
      result = [...result, ...(page?.data || [])];
    });

    return result;
  };

  const onPopupScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.persist();
    const target = e.target as TargetType;

    if (Math.abs(target.scrollTop + target.offsetHeight - target.scrollHeight) < 1) {
      fetchNextPage();
    }
  };

  const onChange: SelectProps["onChange"] = (e, option) => {
    const arg = { ...option } as DefaultOptionType;

    form?.setFieldValue("unit_id", arg?.props?.unitId);
  };

  const onSearch: SelectProps["onSearch"] = value => {
    clearTimeout(time);

    setTime(
      setTimeout(() => {
        setSearch(value);
      }, 800)
    );
  };

  return (
    <Item name="product_id" rules={rules} label={label}>
      <Select
        showSearch
        allowClear={allowClear}
        suffixIcon={<SelectSuffixIcon />}
        filterOption={selectFilterOption}
        placeholder={t("dashboard.Mahsulotni tanlang")}
        onSearch={onSearch}
        onPopupScroll={onPopupScroll}
        onChange={onChange}
        notFoundContent={
          <Spin spinning={isLoading} indicator={LoadingIndicator}>
            <SelectNotContent title={t("dashboard.Mahsulot")} />
          </Spin>
        }
      >
        {products()?.map(product => (
          <Option
            value={product?.id}
            key={`${product?.id}-${product?.unit?.id}`}
            props={{
              unitId: product?.unit?.id,
              name: product?.name?.[i18n.language]
            }}
          >
            <div className="flex w-full gap-3">
              <div
                className="flex items-center rounded border border-solid px-2 text-xs font-medium"
                style={{
                  borderColor: product?.resource?.color,
                  color: product?.resource?.color
                }}
              >
                {product?.resource?.symbol?.[i18n.language]}
              </div>
              <div className="flex flex-1 items-center justify-between gap-4 text-base font-normal text-gray-800">
                <TooltipShortName length={54} title={product?.name?.[i18n.language]} />
                <div className="text-sm font-medium text-gray-400">{product?.unit?.symbol?.[i18n.language]}</div>
              </div>
            </div>
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default ProductSelect;
